fecha = new Date();
now = "#{fecha.getDate()}-#{fecha.getMonth()+1}-#{fecha.getFullYear()}"
$.get 'http://www.mindicador.cl/api/uf/'+now, (response)->
	$('.uf').text response.serie[0].valor.toLocaleString("es-ES")
, 'json'
$('body').on 'hidden.bs.modal', '.modal', ()->
	$(this).removeData('bs.modal')
$('[data-title]').tooltip(
	container: 'body'
	trigger: 'hover'
)
# Cargo funciones dentro del DOM dinamico de modales remotos
$('.modal').on 'loaded.bs.modal hidden.bs.modal', (event)->
	modal = $(@)
	rel = $(event.relatedTarget)
	if rel.has('[href]')
		if event.type is 'loaded'
			imagenPrincipal = modal.find('#imagen-principal')
			if imagenPrincipal.length > 0
				imagenPrincipal.find('img').on 'click', (event) ->
					$form = imagenPrincipal.parents('form')
					$form.find('input[name=imagen_principal]').val $(@).data('imagen')
					$form.trigger 'submit'
			cont_dropzone = modal.find('.dropzone')
			if cont_dropzone.length > 0
				url = cont_dropzone.attr('action')
				cont_dropzone.dropzone({
					addRemoveLinks: true
					dictDefaultMessage: 'Presione o suelte los archivos aquí'
					dictRemoveFile: 'Eliminar'
					removedfile: (imagen)->
						$.post "#{url}/eliminar", {'_token':window.khaus.token,'imagen':imagen.name}, (response)->
							$(imagen.previewElement).remove()
				})
				$.get url, (response)->
					$.each response, (k, value)->
						imagen =
							name: value.name.replace(/^(img\/productos\/[0-9]+\/)/, '')
							size: value.size
						Dropzone.forElement('.dropzone').emit('addedfile', imagen)
						Dropzone.forElement('.dropzone').emit('thumbnail', imagen, " #{window.baseURL}#{value.name}")
						Dropzone.forElement('.dropzone').emit('complete', imagen)
			khausForm = modal.find('form.khaus-form')
			if khausForm.length > 0
				khausForm.khausForm(
					onSuccess: (form)->
						form.parents('.modal').modal('hide')
				)

spanishLocation = window.baseURL + 'vendor/datatables-plugins/i18n/Spanish.lang'
spanishLocation = '//cdn.datatables.net/plug-ins/f2c75b7247b/i18n/Spanish.json'

datatable = $('.datatable').dataTable(
	stateSave: true
	pageLength: 12
	language:
		url: spanishLocation
		sDom: "<'row'<'col-sm-6'l><'col-sm-6'f>r>t<'row'<'col-sm-4'i><'col-sm-8'p>>"
)
$("#light-slider").lightSlider(
	gallery: true
	item: 1
)

